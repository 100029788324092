import {states} from "../../constants/States";
import {createSelector} from "reselect";
import {getActiveCompany} from "./companySelector";

/**
 * Get info current user
 *
 * @param state
 * @returns {{}}
 */
export const getUser = (state) => {
    return state.auth.user || {};
}

/**
 * Get user UUID
 *
 *  @returns {string}
 */
export const getUserUuid = createSelector(
    [getUser],
    (user) => {
        return user.sub || '';
    }
)

/**
 * Check if token is set in redux store
 *
 * @param state
 * @returns {boolean}
 */
export const isTokenSet = (state) => {
    return Boolean(state.auth.token);
}

/**
 * Check if User is loggedIn by localState
 *
 *  @returns {boolean}
 */
export const isLoggedIn = () => {
    const exists = localStorage.getItem('loggedin');
    return exists || false
}

/**
 * Check if Scoretrace Admin
 *
 *  @returns {boolean}
 */
export const isScoretraceAdmin = createSelector([getActiveCompany, getUser], (activeCompany, user) => {
    if (!activeCompany) {
        return user.isAdmin || false;
    }
    return activeCompany?.roles?.includes(states.STATE_ROLE_ADMIN) || false;
});

/**
 *  Returns fullname of logged in user
 *
 *  @returns {string}
 */
export const getLoggedInUsername = createSelector(
    [
        getUser,
    ],
    (user) => {
        return user?.name || ''
    }
);
